/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "assets/theme/tw-permit-me-custom/theme.css";

html {
  font-size: 14px;
}

.active-link {
  background-color: rgba(255,255, 255, 0.7);
  color: #003767;
  border-radius: 5px;
}
@media print {
  .hideWhenPrinted{
    display: none !Important;
  }
  .removeMarginWhenPrint{
    margin: 0 !Important;
    background-color: #fff !Important;
  }

}

